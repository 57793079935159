import { Label, PrimaryButton, TextField } from '@fluentui/react'
import { useCallback, useState } from "react"
import { isEmptyOrSpaces } from '../../utils/text'
import css from './AddCustomValue.module.scss'

export default function AddCustomValue({ onDone, onAdd }: { onDone: React.DispatchWithoutAction, onAdd(key: string, value:string): void }) {
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    
    const handleAdd = useCallback(() => {
        if(isEmptyOrSpaces(key)) {
            return;
        }

        onAdd(key, value)
        onDone()
    }, [onDone, onAdd, key, value])

    const handleCancel = useCallback(() => {
        onDone()
    }, [onDone])

    return <div className={css.addCustomValue}>
        <Label>Add new custom value:</Label>
        <TextField label='Key' value={key} onChange={(e, v) => setKey(v ?? '')} autoFocus />
        <TextField label='Value' value={value} onChange={(e, v) => setValue(v ?? '')} />
        <div className={css.buttonsCustomValues}>
            <PrimaryButton onClick={() => handleCancel()}>Cancel</PrimaryButton>
            <PrimaryButton onClick={() => handleAdd()}>Add</PrimaryButton>
        </div>
    </div>

}
