import css from './TenantConsentResultPage.module.scss'
import { useQuery } from "../../utils/routeQuery";
import LoginPage from "../login/LoginPage";
import { useNavigate } from "react-router";
import { PrimaryButton } from '@fluentui/react';

export default function TenantConsentResultPage() {
    const navigate = useNavigate();
    let query = useQuery();
    let admin_consent = query.get("admin_consent")
    let error = query.get("error")
    let errorDescription = query.get("error_description")
    let tenant = query.get("tenant")

    // see: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-admin-consent
    // for documentation on the expected response

    if(admin_consent !== 'True' && !error)
    {
        return <LoginPage />
    }

    return <form className={css.tenantConsentResultPage}>
        {
            !error && <div className={css.successful}>
                <label>Tenant Id {tenant} Consented to use Presence Server</label>
                <PrimaryButton onClick={() => navigate('/')}>Login</PrimaryButton>
            </div>
        }
        {
            error && <div className={css.error}>
                <label>Tenant Id {tenant} Failed to Consent to use Presence Server</label>
                <p>Consent error: {error}</p>
                <p>{errorDescription}</p>
                <PrimaryButton onClick={() => navigate('/')}>Login</PrimaryButton>
            </div>
        }
        </form>
}

