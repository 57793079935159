import { all } from "async-saga"
import { actions } from ".."
import { createAzureAdClient } from "../../api"
import { takeEvery } from "./types"

const logout = takeEvery(actions.logout, async (ctx) => {
    const client = createAzureAdClient('id')
    client.logout()
})

export default all(
    logout
)