import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { actions, useSelector } from "./store"
import { selectGraphUser, selectPhoto } from "./store/reducers/graph"

export function usePhoto(graphUserId: string) {
    const dispatch = useDispatch()
    const photo = useSelector(s => selectPhoto(s, graphUserId))
    const [url, setUrl] = useState<string>()

    useEffect(() => {
        if (photo === undefined) {
            dispatch(actions.fetchPhoto.request(graphUserId))
        }
    }, [dispatch, graphUserId, photo])

    useEffect(() => {
        if (!photo) { return }

        const url = URL.createObjectURL(photo)
        setUrl(url)

        return () => {
            URL.revokeObjectURL(url)
            setUrl(undefined)
        }
    }, [photo])

    return url
}

export function useGraphUser(graphUserId: string | undefined) {
    const dispatch = useDispatch()
    const user = useSelector(s => selectGraphUser(s, graphUserId))

    useEffect(() => {
        if (graphUserId !== undefined && user === undefined) {
            dispatch(actions.fetchGraphUser.request(graphUserId))
        }
    }, [dispatch, graphUserId, user])

    return user
}