
import { AuthenticationResult } from '@azure/msal-browser'
import azureAdClient, { AzureAdClient } from '../azure-ad'
import { ensureSuccess, withEnricher } from '../utils/network'
import createGraphClient from './graphApi'
import { getBearerTokenEnricher } from './presence'


const AzureAdClients: Partial<Record<TokenType | '-', AzureAdClient>> = {}

export type TokenType = 'api' | 'graph' | 'id'

export function createAzureAdClient(type?: TokenType, appId?: string) {
    const key = type ?? '-'
    let client = AzureAdClients[key]
    if (client) { return client }

    client = azureAdClient({
        clientId: appId ?? globalConfig.clientId,
        redirectUrl: globalConfig.publicUrl + '/blank',
        scopes: type === undefined ? [] : getScopes(type),
        authority: 'https://login.microsoftonline.com/' + globalConfig.authenticationTenantId,
    })

    AzureAdClients[key] = client
    return client
}

export function getFullBaseUrl() {
    return `${window.location.origin}${globalConfig.publicUrl}`
}


function getScopes(type: TokenType) {
    switch (type) {
        case 'api': return ['api://' + globalConfig.clientId + '/access_as_user']
        case 'graph': return ['User.Read', 'User.Read.All']
        case 'id': return ['User.Read']
    }
}

let acquireTokenPromise: Promise<{ type: TokenType, token: string }> | undefined

export function acquireToken(type: TokenType, appId?: string) {
    if (acquireTokenPromise) {
        return (async () => {
            const promiseResult = await acquireTokenPromise
            if (promiseResult.type === type) {
                return promiseResult.token
            }

            const client = createAzureAdClient(type, appId)
            const res = await client.acquireSilent()
            if (!res) {
                throw new Error('Token aquisition failed')
            } else {
                return getToken(client, res)
            }
        })()
    }

    acquireTokenPromise = (async () => {
        const client = createAzureAdClient(type)

        let res = await client.acquireSilent()
        if (!res) {
            console.warn('Acquiring', type, 'token silently failed. Retrying with a popup')
            res = await client.acquirePopup()
        }

        return {
            type,
            token: getToken(client, res),
        }
    })()

    return acquireTokenPromise.then(res => {
        // tokenRefreshedEvent.emit()
        return res
    }).then(res => res.token).finally(() => {
        acquireTokenPromise = undefined
    })

    function getToken(client: AzureAdClient, res: AuthenticationResult) {
        return type === 'id' ? client.getIdToken()! : res.accessToken
    }
}

export async function queryAbsolute<T>(url: string, init?: RequestInit) {
    const resp = await executeAbsolute(url, init)
    return await resp.json() as T
  }
  
  async function executeAbsolute(url: string, init?: RequestInit) {
    // not that I didn't want to check :// to determine which doesn't seem very reliable. Instead I opted for
    // more explicit functions
    const resp = await fetch(url, await withEnricher(getBearerTokenEnricher(), init))
    await ensureSuccess(resp)
    return resp
  }

  export function getGraphClient() {
    return createGraphClient(() => acquireToken('graph'))
  }