import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from './store';
import './index.scss'
import { createTheme, Customizations, initializeIcons } from '@fluentui/react';
import dayjs from 'dayjs'
import 'dayjs/locale/en-nz'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configureStore({})

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.extend(customParseFormat)
dayjs.locale('en-nz')

initializeIcons()
const theme = createTheme({
  palette: {
    themePrimary: '#6264a7',
    themeLighterAlt: '#f7f7fb',
    themeLighter: '#e1e1f1',
    themeLight: '#c8c9e4',
    themeTertiary: '#989ac9',
    themeSecondary: '#7173b0',
    themeDarkAlt: '#585a95',
    themeDark: '#4a4c7e',
    themeDarker: '#37385d',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralSecondaryAlt: '#e1dfdd',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },

  semanticColors: {
    inputBackground: '#f5f5f5',
    inputBorder: 'transparent',
    inputBorderHovered: '', // will make it the same as non-hovered, important for checkboxes
    buttonBorder: '#e1dfdd',
  },
})
Customizations.applySettings({ theme })


root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={globalConfig.publicUrl}>
        <App />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


