import { actions } from ".."
import { selectGraphUser, selectPhoto } from "../reducers/graph"
import { takeEvery } from "./types"
import * as api from '../../api'
import { all } from "async-saga"

const fetchPhoto = takeEvery(actions.fetchPhoto.request, async (ctx, id) => {
    if (selectPhoto(ctx.prevState, id) !== undefined) { return }
    try {
        const data = await api.getGraphClient().getPhoto(id)
        ctx.dispatch(actions.fetchPhoto.response(id, data))
    } catch (e) {
        console.error(e)
    }
})

const fetchGraphUser = takeEvery(actions.fetchGraphUser.request, async (ctx, id) => {
    if (selectGraphUser(ctx.prevState, id) !== undefined) { return }
    try {
        const data = await api.getGraphClient().getUser(id)
        ctx.dispatch(actions.fetchGraphUser.response(data))
    } catch (e) {
        console.error(e)
    }
})


export default all(
    fetchPhoto,
    fetchGraphUser,
)