interface mapFunctionInterface<K, V, R> {
    (value:V, key:K):R;
}

export function mapMap<K, V, R>(map:Map<K, V>, mapFunction:mapFunctionInterface<K, V, R>)
{
    const toReturn:R[] = [];
    map.forEach(function(value, key)
    { 
        // be careful to the args order
        toReturn.push(mapFunction(value, key));
    })
    return toReturn;
}