import { all } from 'async-saga'
import graph from './graph'
import logout from './logout'
import onInit from './onInit'
import presence from './presence'


export default all(
    onInit,
    presence,
    graph,
    logout
)
