import { PrimaryButton, TextField } from '@fluentui/react'
import { useCallback, useState } from "react"
import { useDispatch } from 'react-redux'
import { actions } from '../../store'
import css from './SetStatus.module.scss'

export default function SetStatus({ onDone, currentStatus }: { onDone: React.DispatchWithoutAction, currentStatus: string }) {
    const [status, setStatus] = useState(currentStatus)
    const dispatch = useDispatch()
    
    const handleSet = useCallback(() => {
        console.log(`handleSet: status=${status}`)
        dispatch(actions.setPresence(null, null, status, null, null, false, null))
        onDone()
    }, [dispatch, onDone, status])

    return <form className={css.setStatus} onSubmit={() => handleSet()}>
        <TextField label='Status Text' value={status} onChange={(e, v) => setStatus(v ?? '')} autoFocus />
        <PrimaryButton type="button" onClick={() => handleSet()}>Set</PrimaryButton>
    </form>

}
