

export function convertStringEnum<T extends {[key: number]: string}>(str: string | null | undefined, enumType: T, defaultVal: T[keyof T]): T[keyof T] {
  if (!str) return defaultVal

  if (Object.values(enumType).some((val) => val === str)) {
    return str as unknown as T[keyof T]
  }

  return defaultVal
}
