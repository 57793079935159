import Page from "../Page";

import classNames from 'classnames'
import css from './DashboardPage.module.scss'

interface Props {
    className?: string
}

export default function DashboardPage({ className }: Props) {
    return <Page className={classNames(css.DashboadPage, className)}>
        <h1>Dashboard</h1>
    </Page>
}

