import { Icon } from '@fluentui/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { useGraphUser, usePhoto } from '../../hooks'
import { AvailabilityPresenceState, getActivityPresenceStateText, GraphUser } from '../../model'
import { useSelector } from '../../store'
import { selectUserPresence, UserPresenceStoreState } from '../../store/reducers/presence'
import { getGraphUserId } from '../../store/sagas/presence'
import { isEmptyOrSpaces } from '../../utils/text'
import css from './Badge.module.scss'

type Props = {
    className?: string
    photoClassName?: string
    nameClassName?: string
    size?: string
    children?: React.ReactNode
    id: string
}

export default React.forwardRef<HTMLDivElement, Props>(function Badge({ className, photoClassName, nameClassName, size, id, children, ...rest }, ref) {
    const presenceState = useSelector(s => selectUserPresence(s, id))

    const user = useGraphUser(getGraphUserId(presenceState.externalIds))
    const displayData = React.Children.count(children) === 0 ? (user?.displayName && 
                                <div className={classNames(css.name, nameClassName)}>
                                    <div>{user?.displayName}</div>
                                    <div>{getActivityPresenceStateText(presenceState)}</div>
                                    {presenceState.isOutOfOffice && presenceState.outOfOfficeMessage && !isEmptyOrSpaces(presenceState.outOfOfficeMessage) && <div>{presenceState.outOfOfficeMessage}</div>}
                                    {presenceState.idle && presenceState.idleSince && <div>Idle for {dayjs(presenceState.idleSince).toNow(true)}</div>}
                                    {presenceState.returnTime && <div>Returns {dayjs(presenceState.returnTime).fromNow()}</div>}
                                    {Object.keys(presenceState.customData).map(id => (<div key={id}>{id}: {presenceState.customData[id]}</div>))}
                                    {presenceState.status && <div>{presenceState.status}</div>}
                                </div>)
                        : <div className={classNames(css.name, nameClassName)}>{children}</div>

    return <div ref={ref} className={classNames(css.badge, className)} style={{ '--size': size } as any}>
        {user && <ProfilePhoto user={user} className={photoClassName} presence={presenceState} />}
        {displayData}
    </div>
})

export interface ProfilePhotoProps {
    user: GraphUser
    size?: string
    className?: string
    presence: UserPresenceStoreState | null
}

export function ProfilePhoto({user, className, presence, size }: ProfilePhotoProps) {
    const [error, setError] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const photo = usePhoto(user.id)

    useEffect(() => {
        setError(false)
    }, [photo])

    const onError = useCallback(() => {
        setError(true)
    }, [])

    const initials = getInitials(user.displayName ?? user.userPrincipalName) || '?'

    return <div ref={ref} className={classNames(css.photo, className)} style={{ '--size': size } as any} title={user.displayName}>
        {error || !photo ?
            <svg viewBox='0 0 30 30' style={getColors(user.id)}>
                <text x='50%' y='55%' dominantBaseline='middle' textAnchor='middle'>{initials}</text>
            </svg>
            : <>
                <img alt='' src={photo} onError={onError} />
                <div className={css.shadow} />
            </>}
        {presence !== null && <PresenceBadge availability={presence.availability} isOutOfOffice={presence.isOutOfOffice} className={css.presence} />}

    </div>
}
interface PresenceBadgeProps {
    availability: AvailabilityPresenceState
    isOutOfOffice: boolean
    className?: string
}

export function PresenceBadge({ availability, isOutOfOffice, className }: PresenceBadgeProps) {
    const presenceColour = getPresenceBadgeColour(availability, isOutOfOffice)
    const presenceBorderColour = getPresenceBadgeBorderColour(availability, isOutOfOffice) ?? presenceColour
    const presenceIcon = getPresenceBadgeIcon(availability, isOutOfOffice)
    return <div className={classNames(css.presenceBadge, className)} style={{ '--presenceColour': presenceColour, '--presenceBorderColour': presenceBorderColour } as CSSProperties}>
        {presenceIcon && <Icon className={css.icon} iconName={presenceIcon[0]} style={{ color: presenceIcon[1] }} />}
    </div>
}

function getPresenceBadgeColour(presence: AvailabilityPresenceState, isOutOfOffice: boolean) {
    if(isOutOfOffice) {
        return '#BE21AA'
    }

    switch (presence) {
        case AvailabilityPresenceState.available: return '#00B050'
        case AvailabilityPresenceState.busy: return '#FF0000'
        case AvailabilityPresenceState.doNotDisturb: return '#FF0000'
        case AvailabilityPresenceState.beRightBack: return '#FFC000'
        case AvailabilityPresenceState.away: return '#FFC000'
        case AvailabilityPresenceState.offline: return '#FFFFFF'
    }
    // eslint-disable-next-line
    const _exhaustive: never = presence
}

function getPresenceBadgeBorderColour(presence: AvailabilityPresenceState, isOutOfOffice: boolean) {
    if(isOutOfOffice) {
        return '#BE21AA'
    }

    switch (presence) {
        case AvailabilityPresenceState.available: return '#FFFFFF'
        case AvailabilityPresenceState.busy: return '#FFFFFF'
        case AvailabilityPresenceState.away: return '#8A8886'
        case AvailabilityPresenceState.offline: return '#8A8886'
    }
    return null
}

function getPresenceBadgeIcon(presence: AvailabilityPresenceState, isOutOfOffice: boolean): [string, string] | null {
    if(isOutOfOffice) {
        switch (presence) {
            case AvailabilityPresenceState.available: return ['SkypeCheck', '#000000']
            case AvailabilityPresenceState.busy: return ['SkypeMinus', '#000000']
            case AvailabilityPresenceState.doNotDisturb: return null
            case AvailabilityPresenceState.beRightBack: return ['SkypeArrow', '#000000']
            case AvailabilityPresenceState.away: return ['SkypeArrow', '#000000']
            case AvailabilityPresenceState.offline: return ['SkypeArrow', '#000000']
        }
    }

    switch (presence) {
        case AvailabilityPresenceState.available: return ['SkypeCheck', '#000000']
        case AvailabilityPresenceState.busy: return null
        case AvailabilityPresenceState.doNotDisturb: return ['SkypeMinus', '#000000']
        case AvailabilityPresenceState.beRightBack: return ['SkypeClock', '#000000']
        case AvailabilityPresenceState.away: return ['SkypeClock', '#000000']
        case AvailabilityPresenceState.offline: return null
    }
    // eslint-disable-next-line
    const _exhaustive: never = presence
}

function getInitials(name: string) {
    return (/(.)[^ ]+ ?(?:\(.*?\) ?)?(.)?/.exec(name) || []).slice(1).join('').toUpperCase()
}

function getColors(uniqueId: string): React.CSSProperties {
    const hue = hash(uniqueId) % 360
    return {
        background: `hsl(${hue}, 50%, 90%)`,
        color: `hsl(${hue}, 80%, 25%)`,
    }
}

function hash(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i)
        hash = (((hash << 5) - hash) + chr) | 0
    }
    return hash
}


