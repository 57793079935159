import { Actions, StoreState } from '../types'
import { actions } from '..'
import { getType } from 'typesafe-actions'
import { GraphUser } from '../../model'
import { combineReducers } from 'redux'

type PhotosState = Map<string, Blob | null | undefined>

function photos(state: PhotosState = new Map<string, Blob | null | undefined>(), action: Actions): PhotosState {
  switch (action.type) {
    case getType(actions.fetchPhoto.response): {
      const { id, photo } = action.payload
      state.set(id, photo);
      return new Map<string, Blob | null | undefined>(state)
    }
  }
  return state
}

type UsersState = Map<string, GraphUser | null | undefined>

function users(state: UsersState = new Map<string, GraphUser | null | undefined>(), action: Actions): UsersState {
  switch (action.type) {
    case getType(actions.fetchGraphUser.response): {
      if (action.payload) {
        state.set(action.payload.id, action.payload)
        return new Map<string, GraphUser | null | undefined>(state);
      }

      return state;
    }
  }
  return state
}

function getRoot(state: StoreState) { return state.graph }

export function selectPhoto(state: StoreState, graphUserId: string) {
  return getRoot(state).photos.get(graphUserId)
}

export function selectGraphUser(state: StoreState, graphUserId: string | undefined) {
  if(graphUserId === undefined) return undefined
  return getRoot(state).users.get(graphUserId)
}


export default combineReducers({
  photos,
  users,
})