import { all } from 'async-saga'
import dayjs from 'dayjs'
import { actions } from '..'
import { acquireToken } from '../../api'
import presenceNotifications, { clearClientUserOutOfOffice, clearScheduledClientUserPresences, fetchAllPresencesPage, popClientUserPresence, PresenceUserState, registerClient, registerClientUser, scheduleSetClientUserPresence, setClientUserIdle, setClientUserOutOfOffice, setClientUserPresence } from '../../api/presence'
import { selectUser } from '../reducers/login'
import { SagaContext, takeEvery, waitForState } from './types'

async function processPresenceNotifications(ctx: SagaContext) {
  await waitForState(ctx, selectUser)

  await presenceNotifications(() => acquireToken('api'), {
    registerClientUserResponse(state) {
      console.log('registerClientUserResponse', state)
      ctx.dispatch(actions.presenceRegistered(state))
    },
    userPresenceChange(state) {
      const login = ctx.getState().login.login
      if (login === null) return
      ctx.dispatch(actions.userPresencesChanged([state]))
    },
    userPresenceDelete(state) {
      const login = ctx.getState().login.login
      if (login === null) return
      ctx.dispatch(actions.userPresenceDeleted(state))
    },
    onConnected(reconnect) {
      console.log(`presence: onConnected(${reconnect})`)
      readAllPresenceUsers(ctx)
      ctx.dispatch(actions.registerClientUser())
    },
    onDisconnected(err) {
      console.log(`presence: onDisconnected()`, err)
      ctx.dispatch(actions.presenceDisconnected())
    },
})
}

async function readAllPresenceUsers(ctx: SagaContext) {
  await waitForState(ctx, selectUser)
  let page = 1
  let totalPages = 1

  while(page <= totalPages)
  {
    let result = await fetchAllPresencesPage(page)
    if(!result) break;
    totalPages = result.totalPages
    ctx.dispatch(actions.userPresencesChanged(result.items))
    ++page
  }
  ctx.dispatch(actions.allPresencesLoaded())
}

const registerClientUserSaga = takeEvery(actions.registerClientUser, async (ctx) => {
  const user = ctx.getState().login.login
  const presence = ctx.getState().presence.session.presence
  async function register(presence: PresenceUserState) {
    await registerClient('PresenceWebSite')
    
    const param = {
      defaultActivity: presence.activity,
      defaultAvailability: presence.availability,
      defaultStatus: presence.status,
      idle: presence.idle,
      idleSince: presence.idleSince,
      customData: presence.customData
    }
    await registerClientUser(param)
  }
  if (user) {
    register(presence)
  }
})

const setPresence = takeEvery(actions.setPresence, async (ctx, presence) => {
  const session = ctx.getState().presence.session
  if (!session) return
  setClientUserPresence({
    presenceId: session.id,
    availability: presence.availability as string,
    activity: presence.activity as string,
    status: presence.status ?? undefined,
    returnTime: presence.returnTime ? dayjs(presence.returnTime).toISOString() : undefined,
    customData: presence.customValues ? presence.customValues : undefined,
    pushCurrentPresence: presence.pushCurrentPresence,
    preservePresenceWhenOffline: presence.preservePresenceWhenOffline !== null ? presence.preservePresenceWhenOffline : undefined
  })
})

const setOutOfOffice = takeEvery(actions.setOutOfOffice, async (ctx, outOfOffice) => {
  const session = ctx.getState().presence.session
  if (!session) return
  setClientUserOutOfOffice({
    presenceId: session.id,
    message: outOfOffice.message
  })
})

const clearOutOfOffice = takeEvery(actions.clearOutOfOffice, async (ctx) => {
  const session = ctx.getState().presence.session
  if (!session) return
  clearClientUserOutOfOffice({
    presenceId: session.id
  })
})

const setClientIdle = takeEvery(actions.setClientIdle, async (ctx, idle) => {
  const session = ctx.getState().presence.session
  if (!session) return
  setClientUserIdle({
    presenceId: session.id,
    idle: idle.idle,
    idleSince: idle.sinceTime != null ? dayjs(idle.sinceTime).toISOString() : undefined
  })
})

const setScheduledPresence = takeEvery(actions.setScheduledPresence, async (ctx, presence) => {
  const session = ctx.getState().presence.session
  if (!session) return
  scheduleSetClientUserPresence({
    presenceId: session.id,
    availability: presence.availability as string,
    activity: presence.activity as string,
    startTime: dayjs(presence.scheduledStartTime).toISOString(),
    duration: presence.duration ?? undefined,
    status: presence.status ?? undefined,
    customData: presence.customData ?? undefined,
    pushCurrentPresence: presence.pushCurrentPresence,
    preservePresenceWhenOffline: presence.preservePresenceWhenOffline ?? undefined
  })
})

const clearScheduledPresences = takeEvery(actions.clearScheduledPresences, async (ctx) => {
  const session = ctx.getState().presence.session
  if (!session) return
  clearScheduledClientUserPresences({
    presenceId: session.id
  })
})

const popPresence = takeEvery(actions.popPresence, async (ctx) => {
  const session = ctx.getState().presence.session
  if (!session) return
  popClientUserPresence({
    presenceId: session.id
  })
})

export function getUpn(ids: Record<string, string>): string | undefined {
  return ids['UserPrincipalName']
}

export function getGraphUserId(ids: Record<string, string>): string | undefined {
  return ids['GraphUserId']
}

export function getInternalId(ids: Record<string, string>): string | undefined {
  return ids['_id']
}

export default all(
  processPresenceNotifications,
  registerClientUserSaga,
  setPresence,
  setClientIdle,
  setOutOfOffice,
  clearOutOfOffice,
  setScheduledPresence,
  clearScheduledPresences,
  popPresence
)
