import { GraphUser } from '../model'
import { ensureSuccess, HttpError } from '../utils/network'


interface GraphCollection<T> {
    value: T[]
    '@odata.nextLink': string
}

function createClient(getToken: () => Promise<string>) {
    return {
        async getResponse(url: string, init?: RequestInit) {
            if (!url.startsWith('https://')) {
                url = 'https://graph.microsoft.com/v1.0' + url
            }
            const resp = await fetch(url, {
                ...init,
                headers: {
                    Authorization: `Bearer ${await getToken()}`,
                    ...(init && init.headers),
                },
            })
            await ensureSuccess(resp)
            return resp
        },

        async fetch<T>(url: string, init?: RequestInit): Promise<T> {
            const resp = await this.getResponse(url, init)
            if (resp.status === 204) { return undefined! }
            return await resp.json()
        },

        async fetchCollection<T>(url: string, init?: RequestInit): Promise<T[]> {
            const result: T[] = []

            let resp = await this.fetch<GraphCollection<T>>(url, init)

            for (; ;) {
                result.push(...resp.value)
                const next = resp['@odata.nextLink']
                if (!next) { break }
                resp = await this.fetch<GraphCollection<T>>(next)
            }

            return result
        },
    }
}

export default function createGraphClient(getToken: () => Promise<string>) {
    const graph = createClient(getToken)

    return {
        async getPhoto(id: string) {
            try {
                const resp = await graph.getResponse(`/users/${encodeURIComponent(id)}/photo/$value`)
                return await resp.blob()
            } catch (e) {
                if (e instanceof HttpError && e.status === 404) {
                    return null
                }
                throw e
            }
        },
        async getUser(id: string) {
            try {
                const resp = await graph.getResponse(`/users/${encodeURIComponent(id)}?$select=id,userPrincipalName,mail,displayName,jobTitle`)
                return await resp.json() as GraphUser
            } catch (e) {
                if (e instanceof HttpError && e.status === 404) {
                    return null
                }
                throw e
            }
        },
    }
}

