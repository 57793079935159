
export async function ensureSuccess(resp: Response) {
  if (!resp.ok) {
    let body = null

    try {
      body = await resp.text()
    } catch (e) { }

    throw new HttpError(resp.status, resp.statusText, body)
  }
}

export class HttpError extends Error {
  public readonly status: number
  public readonly statusText: string
  public readonly body: string | null

  constructor(status: number, statusText: string, body: string | null) {
    super(`${status} ${statusText}`)

    this.name = 'HttpError'
    this.status = status
    this.statusText = statusText
    this.body = body

    Object.setPrototypeOf(this, HttpError.prototype)
  }
}

export async function withEnricher(enricher: RequestEnricher, init: RequestInit = {}) {
  await enricher?.(init)
  return init
}

export type RequestEnricher = undefined | ((req: RequestInit) => Promise<void>)

export function createBearerTokenEnricher(getToken: () => Promise<string>): NonNullable<RequestEnricher> {
  return async init => {
    if (!(init.headers instanceof Headers)) {
      init.headers = new Headers(init.headers)
    }
    init.headers.append('Authorization', 'Bearer ' + await getToken())
  }
}

export function reqInit(method: string, body?: any) {
  return {
    method,
    headers: body === undefined ? undefined : { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
}

export function postInit(body?: any): RequestInit {
  return reqInit('POST', body)
}

export function queryUrl(url: string, params: Record<string, string | number | boolean | null | undefined>) {
  const query = Object.entries(params)
    .filter(([k, v]) => v !== undefined)
    .map(([k, v]) => `${k}=${encodeURIComponent(v as any)}`)
    .join('&')

  return query ? `${url}?${query}` : url
}

export function backoff(retries: number) {
    const s = 1000
    const base = 1 * s
    const exp = 1.5
    const max = 30 * s
    const min = 1 * s
    const jitter = 2 * s
  
    const result = Math.min(max, base * (Math.pow(exp, retries) - 1) + min)
    return result + Math.random() * jitter
  }
  