import { IconButton } from '@fluentui/react'
import classNames from 'classnames'
import React, { DispatchWithoutAction } from 'react'
import ReactModal from 'react-modal'
import css from './Dialog.module.scss'

type Props = {
  isOpen: boolean
  onDismiss?: DispatchWithoutAction
  overlayClassName?: string
  wrapperClassName?: string
  contentClassName?: string
  autoFocus?: boolean
  noX?: boolean
  scroll?: boolean
  full?: boolean
  headerToolbar?: React.ReactNode
}

export default function Dialog({ isOpen, onDismiss, children, overlayClassName, headerToolbar, wrapperClassName, contentClassName, autoFocus = true, scroll, noX, full }: React.PropsWithChildren<Props>) {
  // react-modal doesn't render modal components in the expected order if you
  // only use the isOpen prop to determine if a modal is shown or not
  if (!isOpen) { return null }

  return <ReactModal closeTimeoutMS={267} isOpen={isOpen} onRequestClose={onDismiss} shouldCloseOnOverlayClick
    shouldFocusAfterRender={autoFocus} className={classNames(css.wrapper, scroll && css.scroll, full && css.full, wrapperClassName)}
    overlayClassName={classNames(css.overlay, overlayClassName)} portalClassName={css.portal} ariaHideApp={false}>
    <div className={classNames(css.modal, contentClassName)}>
      {onDismiss && !noX && <IconButton className={css.close} iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />}
      {headerToolbar && <div className={css.headerToolbar} >{headerToolbar}</div>}
      {children}
    </div>
  </ReactModal>
}

export function DialogHeader({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
  return <div className={classNames(css.header, className)} {...props} />
}

export function DialogBody({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames(css.content, className)} {...props} />
}
