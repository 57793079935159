
import { createSagaMiddleware } from 'async-saga'
import { useSelector as useUntypedSelector } from 'react-redux'
import { applyMiddleware, createStore, DeepPartial } from 'redux'
import { createLogger } from 'redux-logger'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { createRootReducer } from './reducers'
import rootHandler from './sagas'
import { Actions, StoreState } from './types'

const skipLog = [].map<string>(a => getType(a))

const loggerMiddleware = createLogger({
  predicate: (getState, action: Actions) => !skipLog.includes(action.type),
})

export function configureStore(preloadedState: DeepPartial<StoreState>) {
    const sagas = createSagaMiddleware(rootHandler)
  
    const store = createStore(
      createRootReducer(),
      preloadedState as any,
      applyMiddleware(
        loggerMiddleware,
        sagas,
      ),
    )
  
    sagas.run()
  
    return store
  }
  
  export { actions }
  
  export function useSelector<TSelected>(selector: (state: StoreState) => TSelected, equalityFn?: (a: TSelected, b: TSelected) => boolean): TSelected {
    return useUntypedSelector<StoreState, TSelected>(selector, equalityFn)
  }
  