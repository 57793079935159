import { ComboBox, IComboBoxOption, IComboBoxProps } from '@fluentui/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { range } from 'seqitr'
import { Extend } from '../utils/type'
import css from './TimePicker.module.scss'

type Props = Extend<Partial<IComboBoxProps>, {
  value: number
  onChange(value: number): void
}>

export default function TimePicker({ value, onChange, className, id, ...rest }: Props) {
  function onChanged(option?: IComboBoxOption, value?: string) {
    if (option) {
      onChange(option.data)
    } else if (value) {
      const val = parse(value)
      if (val !== undefined) {
        onChange(val)
      }
    }
  }

  const options = useMemo(() => range(48).map(hr => hr / 2).map<IComboBoxOption>(sec => ({
    key: sec,
    data: sec,
    text: format(sec),
    selected: sec === value,
  })).toArray(), [value])

  return <ComboBox
    className={classNames(css.timePicker, className)}
    text={format(value)}
    iconButtonProps={{ id }}
    buttonIconProps={{ iconName: 'Clock' }}
    allowFreeform
    autoComplete='on'
    useComboBoxAsMenuWidth
    openOnKeyboardFocus
    {...rest}
    options={options}
    onChange={(_e, o, _i, v) => onChanged(o, v)}
    calloutProps={{ calloutMaxHeight: 300 }}
  />
}

function format(hr: number) {
  return dayjs.utc(0).add(hr, 'h').format('LT')
}

function parse(time: string) {
  const d = dayjs(time.toUpperCase(), 'LT')
  if (!d.isValid()) { return }
  return d.diff(d.startOf('d'), 'h', true)
}
