
export function compareStrings(s1: string, s2: string) {
    if (s1 === s2) { return 0 }
    return s1 < s2 ? -1 : 1
}

export function compareStringsIgnoreCase(s1: string, s2: string) {
    return compareStrings(s1.toLocaleLowerCase(), s2.toLocaleLowerCase())
}

export function stringsEqualIgnoreCase(s1: string, s2: string) {
    return s1.toLocaleLowerCase() === s2.toLocaleLowerCase()
}

export function isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
}