import { PresenceUserState } from "./api/presence"
import { UserPresenceStoreState } from "./store/reducers/presence"
import { Extend } from "./utils/type"

export enum AvailabilityPresenceState {
    available = 'Available',
    busy = 'Busy',
    away = 'Away',
    doNotDisturb = 'DoNotDisturb',
    beRightBack = 'BeRightBack',
    offline = 'Offline'
}

export enum ActivityPresenceState {
    available = 'Available',
    queue = 'Queue',
    busy = 'Busy',
    away = 'Away',
    doNotDisturb = 'DoNotDisturb',
    beRightBack = 'BeRightBack',
    inACall = 'InACall',
    inAConferenceCall = 'InAConferenceCall',
    inAMeeting = 'InAMeeting',
    offWork = 'OffWork',
    presenting = 'Presenting',
    urgentInterruptionsOnly = 'UrgentInterruptionsOnly',
    focusing = 'Focusing',
    offline = 'Offline'
}

export function getActivityPresenceStateText(presence: UserPresenceStoreState) {
    if (!presence.active) {
        if(presence.isOutOfOffice)
        {
            return 'Out of office'
        }

        return 'Offline'
    }

    if(presence.isOutOfOffice)
    {
        switch (presence.activity) {
            case ActivityPresenceState.available: return 'Available, Out of office'
            case ActivityPresenceState.busy: return 'Busy, Out of office'
            case ActivityPresenceState.away: return 'Out of office'
            case ActivityPresenceState.doNotDisturb: return 'Do not disturb, Out of office'
            case ActivityPresenceState.beRightBack: return 'Out of office'
            case ActivityPresenceState.inACall: return 'In a call, Out of office'
            case ActivityPresenceState.inAConferenceCall: return 'In a conference call, Out of office'
            case ActivityPresenceState.inAMeeting: return 'In a meeting, Out of office'
            case ActivityPresenceState.offWork: return 'Out of office'
            case ActivityPresenceState.presenting: return 'Presenting, Out of office'
            case ActivityPresenceState.urgentInterruptionsOnly: return 'Urgent interruptions only, Out of office'
            case ActivityPresenceState.focusing: return 'Focusing, Out of office'
            case ActivityPresenceState.offline: return 'Out of office'
        }

        return `${presence.activity}, Out of office`
    }

    switch (presence.activity) {
        case ActivityPresenceState.available: return 'Available'
        case ActivityPresenceState.busy: return 'Busy'
        case ActivityPresenceState.away: return 'Away'
        case ActivityPresenceState.doNotDisturb: return 'Do not disturb'
        case ActivityPresenceState.beRightBack: return 'Be right back'
        case ActivityPresenceState.inACall: return 'In a call'
        case ActivityPresenceState.inAConferenceCall: return 'In a conference call'
        case ActivityPresenceState.inAMeeting: return 'In a meeting'
        case ActivityPresenceState.offWork: return 'Offline'
        case ActivityPresenceState.presenting: return 'Presenting'
        case ActivityPresenceState.urgentInterruptionsOnly: return 'Urgent interruptions only'
        case ActivityPresenceState.focusing: return 'Focusing'
        case ActivityPresenceState.offline: return 'Offline'
    }

    return presence.activity;
}


export interface GraphUser {
    id: string
    userPrincipalName: string
    mail: string | undefined
    displayName: string | undefined
    jobTitle: string | undefined
}

export type UserPresenceState = Extend<PresenceUserState, {
    availability: AvailabilityPresenceState
    activity: ActivityPresenceState | string
}>
