import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { actions } from '..'
import { PresenceUserState } from '../../api/presence'
import { ActivityPresenceState, AvailabilityPresenceState, UserPresenceState } from '../../model'
import { convertStringEnum } from '../../utils/enum'
import { getGraphUserId, getInternalId } from '../sagas/presence'
import { Actions, StoreState } from '../types'
import { UserInfo } from './login'


type SessionState = { id: string, presence: PresenceUserState, active: boolean }

function session(state: SessionState = { id: '', presence: defaultLoginPresence(), active: false }, action: Actions): SessionState {
  switch (action.type) {
    case getType(actions.presenceRegistered): {
      console.log(`MyState id ${action.payload.id} to `, action.payload)
      return {
        id: action.payload.id,
        presence: action.payload,
        active: true
      }
    }
    case getType(actions.userPresencesChanged): {
      if (state.id) {
        const myPresence = action.payload.find(_ => getInternalId(_.externalIds) === state.id)
        if (myPresence) {
          return {
            ...state,
            presence: myPresence
          }
        }
      }
      return state;
    }
    case getType(actions.userPresenceDeleted): {
      if (state.id) {
        if(getInternalId(action.payload.externalIds) === state.id) {
          return {
            ...state,
            presence: action.payload,
            active: false
          }
        }
      }
      return state;
    }
    case getType(actions.presenceDisconnected): {
      return {
        ...state,
        active: false
      }
    }
  }
  return state
}

export type UserPresenceStoreState = UserPresenceState & {
  lastUpdate: number
  active: boolean
}

type GraphUserState = Map<string, string>

function graphUsers(state: GraphUserState = new Map<string, string>(), action: Actions): GraphUserState {
  switch (action.type) {
    case getType(actions.userPresencesChanged): {
      Object.entries(action.payload).forEach((presence) => 
        {
          const id = getInternalId(presence[1].externalIds)
          const graphUserId = getGraphUserId(presence[1].externalIds)
          if (id && graphUserId) {
            state.set(graphUserId, id)
          }
        })
      return new Map<string, string>(state);
    }
    case getType(actions.userPresenceDeleted): {
      const id = getInternalId(action.payload.externalIds)
      if(id) {
        state.delete(id)
        return new Map<string, string>(state);
      }
      return state
    }
    case getType(actions.presenceRegistered): {
      const id = getInternalId(action.payload.externalIds)
      const graphUserId = getGraphUserId(action.payload.externalIds)
      if (id && graphUserId) {
        state.set(graphUserId, id)
        return new Map<string, string>(state);
      }
      return state
    }
  }
  return state
}


type UsersPresenceState = Map<string, UserPresenceStoreState>

function users(state: UsersPresenceState = new Map<string, UserPresenceStoreState>(), action: Actions): UsersPresenceState {
  switch (action.type) {
    case getType(actions.userPresencesChanged): {
      Object.entries(action.payload).forEach((presence) => 
        {
          const presenceValue = presence[1];
          const id = getInternalId(presenceValue.externalIds)
          if (id) {
            state.set(id, getUserPresenceState(state.get(id), presenceValue))
          } else {
            console.error(`Missing internal id`, presenceValue)
          }
        })
      return new Map<string, UserPresenceStoreState>(state);
    }
    case getType(actions.presenceRegistered): {
      const id = getInternalId(action.payload.externalIds)
      if (id) {
        state.set(id, getUserPresenceState(state.get(id), action.payload))
        return new Map<string, UserPresenceStoreState>(state);
      }
      return state
    }
    case getType(actions.userPresenceDeleted): {
      const id = getInternalId(action.payload.externalIds)
      if(id) {
        state.delete(id)
        return new Map<string, UserPresenceStoreState>(state);
      }
      return state
    }
    case getType(actions.allPresencesLoaded): {
      state.forEach((value, key) =>{
        if(!value.active) {
          state.delete(key)
        }
      });
      return new Map<string, UserPresenceStoreState>(state);
    }
    case getType(actions.presenceDisconnected): {
      state.forEach((value, key) => state.set(key, defaultPresence(value.externalIds, value.customData, value.isOutOfOffice, value.outOfOfficeMessage)));
      return new Map<string, UserPresenceStoreState>(state);
    }
  }
  return state
}

function defaultPresence(externalIds: Record<string, string> = {}, customData: Record<string, string> = {}, isOutOfOffice:boolean = false, outOfOfficeMessage:string | undefined = undefined): UserPresenceStoreState {
  return {
    lastUpdate: new Date().valueOf(),
    availability: AvailabilityPresenceState.offline,
    activity: ActivityPresenceState.offline,
    status: undefined,
    returnTime: undefined,
    idle: false,
    idleSince: undefined,
    isOutOfOffice: isOutOfOffice,
    outOfOfficeMessage: outOfOfficeMessage,
    externalIds: externalIds,
    customData: customData,
    active: false
  }
}

function defaultLoginPresence(): UserPresenceStoreState {
  return {
    lastUpdate: new Date().valueOf(),
    availability: AvailabilityPresenceState.available,
    activity: ActivityPresenceState.available,
    status: undefined,
    returnTime: undefined,
    idle: false,
    idleSince: undefined,
    isOutOfOffice: false,
    outOfOfficeMessage: undefined,
    externalIds: {},
    customData: {},
    active: false
  }
}

function getUserPresenceState(state: UserPresenceState | undefined, payload: PresenceUserState): UserPresenceStoreState {
  return {
    ...(state ?? defaultPresence()),
    ...payload,
    lastUpdate: new Date().valueOf(),
    availability: convertStringEnum(payload.availability, AvailabilityPresenceState, AvailabilityPresenceState.offline),
    activity: payload.activity ? payload.activity : ActivityPresenceState.offline,
    active: true
  }
}

function getRoot(state: StoreState) { return state.presence }

export function selectPresenceSession(state: StoreState) {
  return getRoot(state).session
}

export function selectUserPresence(state: StoreState, id: string): UserPresenceStoreState {
  const rootState = getRoot(state);
  const presence = rootState.users.get(id)
  if (!presence) return defaultPresence()
  return presence
}

export function selectGraphUserPresence(state: StoreState, graphUserId: string): UserPresenceStoreState {
  const rootState = getRoot(state);
  const id = rootState.graphUsers.get(graphUserId)
  const presence = id ? rootState.users.get(id) : null;
  if (!presence) return defaultPresence()
  return presence
}

export function selectUserId(state: StoreState, user: UserInfo | null) {
  if (!user) return null
  return getRoot(state).graphUsers.get(user.oid)
}

export function selectMyPresence(state: StoreState): UserPresenceStoreState {
  const graphUserId = state.login.login?.oid
  if (!graphUserId) return defaultPresence()
  return selectGraphUserPresence(state, graphUserId)
}


export default combineReducers({
  session,
  users,
  graphUsers
})

