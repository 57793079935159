import { ContextualMenu, ContextualMenuItemType, Icon, IContextualMenuItem } from '@fluentui/react';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { ActivityPresenceState, AvailabilityPresenceState, getActivityPresenceStateText } from '../model';
import { actions, useSelector } from '../store';
import { selectUser } from '../store/reducers/login';
import { selectMyPresence, selectPresenceSession } from '../store/reducers/presence';
import { getInternalId } from '../store/sagas/presence';
import { Falsy, isTruthy } from '../utils/type';
import ActivityPage from './activity/ActivityPage';
import css from './App.module.scss';
import Badge, { PresenceBadge } from './presence/Badge';
import DashboardPage from './dashboard/DashboardPage';
import Dialog, { DialogBody, DialogHeader } from './Dialog';
import LoginPage from './login/LoginPage';
import RegisterTenantPage from './registerTenant/RegisterTenantPage';
import SetCustomPresence from './presence/SetCustomPresence';
import SetPresenceDuration from './presence/SetPresenceDuration';
import SetStatus from './presence/SetStatus';
import { Tab, Tabs } from './Tabs';
import TenantConsentResultPage from './tenantConsentResult/TenantConsentResultPage';
import SetOutOfOffice from './presence/SetOutOfOffice';

export default function App() {

  const user = useSelector(selectUser)

  return <div className={css.app}>
    {render()}
  </div>

  function render() {
    if (!user) {
      return <LoginBody />
    }

    return <AppBody />
  }
}

const LoginBody = React.memo(() => {
  return <>
    <Routes>
      <Route path='/registertenant' element={<RegisterTenantPage />} />
      <Route path='/tenantconsent' element={<TenantConsentResultPage />} />
      <Route path='/*' element={<LoginPage />} />
    </Routes>
  </>
})

const AppBody = React.memo(() => {
  return <>
    <header>
      <Tabs>
        <TabButton to='/dashboard' title='Dashboard' icon='BIDashboard' />
        <TabButton to='/activity' title='Activity' icon='WorldClock' />
      </Tabs>

      <div className={css.floatRight} />

      <ProfileButton />
    </header>
    <Routes>
      <Route path='/dashboard' element={<DashboardPage />} />
      <Route path='/activity' element={<ActivityPage />} />
      <Route path='/' element={<Navigate to="/activity" replace={true} />} />
    </Routes>
  </>
})


type TabButtonProps = React.PropsWithChildren<{
  to: string
  title: string
  icon: string
}>

function TabButton({ to, title, icon }: TabButtonProps) {
  const match = useMatch(to)
  const navigate = useNavigate()

  return <Tab data-testid={`App${to}`} className={css.tabButton} active={match !== null} onClick={() => navigate(to)}>
    <Icon iconName={icon} />
    <span>{title}</span>
  </Tab>
}

function ProfileButton() {
  const presence = useSelector(selectMyPresence)
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const id = getInternalId(presence.externalIds)
  if(!id) {
    return null
  }

  return <>
    <button ref={buttonRef} className={css.userName} onClick={() => setOpen(true)}>
      <Badge id={id} size='2rem'>{''}</Badge>
    </button>
    <MainMenu open={open} setOpen={setOpen} target={buttonRef.current}>
      <Badge id={id} size='2rem' />
    </MainMenu>
  </>
}

type MainMenuProps = {
  open: boolean
  setOpen: (open: boolean) => void
  target: HTMLElement | null
}

function MainMenu({ open, setOpen, target, children }: React.PropsWithChildren<MainMenuProps>) {
  const dispatch = useDispatch()
  const presence = useSelector(selectMyPresence)
  const session = useSelector(selectPresenceSession)

  const [showDuration, setShowDuration] = useState(false)
  const [showStatus, setShowStatus] = useState(false)
  const [showOutOfOfficeMessage, setShowOutOfOfficeMessage] = useState(false)
  const [showCustomPresence, setShowCustomPresence] = useState(false)

  const createItem = useCallback((key: string, text: string, av: AvailabilityPresenceState, act: ActivityPresenceState) => {
    return {
      key: key,
      text: text,
      onRenderIcon: () => <PresenceBadge className={css.presenceIcon} availability={av} isOutOfOffice={false} />,
      iconProps: {},
      canCheck: true,
      checked: presence.availability === av && presence.activity === act,
      onClick() {
        dispatch(actions.setPresence(av, act, null, null, null, false, null))
      },
    }
  }, [dispatch, presence.availability, presence.activity])

  // available, busy, do not disturb, be right back, apear away, appear offline
  const subItems = useMemo<IContextualMenuItem[]>(() => {
    return [
      createItem('available', 'Available', AvailabilityPresenceState.available, ActivityPresenceState.available),
      createItem('available', 'Queue', AvailabilityPresenceState.available, ActivityPresenceState.queue),
      createItem('busy', 'Busy', AvailabilityPresenceState.busy, ActivityPresenceState.busy),
      createItem('doNotDisturb', 'Do not disturb', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.doNotDisturb),
      createItem('beRightBack', 'Be right back', AvailabilityPresenceState.beRightBack, ActivityPresenceState.beRightBack),
      createItem('appearAway', 'Appear away', AvailabilityPresenceState.away, ActivityPresenceState.away),
      createItem('appearOffline', 'Appear offline', AvailabilityPresenceState.offline, ActivityPresenceState.offWork),
      createItem('inACall', 'In A Call', AvailabilityPresenceState.busy, ActivityPresenceState.inACall),
      createItem('inAConferenceCall', 'In A Conference Call', AvailabilityPresenceState.busy, ActivityPresenceState.inAConferenceCall),
      createItem('inAMeeting', 'In A Meeting', AvailabilityPresenceState.busy, ActivityPresenceState.inAMeeting),
      createItem('presenting', 'Presenting', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.presenting),
      createItem('urgentInterruptionsOnly', 'Urgent Interruptions Only', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.urgentInterruptionsOnly),
      createItem('focusing', 'Focusing', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.focusing), {
        key: 'divider_1',
        itemType: ContextualMenuItemType.Divider,
      }, {
        key: 'duration',
        text: 'Duration',
        iconProps: { iconName: 'Clock' },
        onClick: _e => setShowDuration(true),
      }, {
        key: 'clearstatus',
        text: 'Clear Status',
        iconProps: { iconName: 'SyncStatus' },
        disabled: !!!presence.status,
        onClick: _e => { dispatch(actions.setPresence(null, null, '', null, null, false, null)) },
      }, {
        key: 'status',
        text: 'Set Status',
        iconProps: { iconName: 'StatusCircleInfo' },
        onClick: _e => setShowStatus(true),
      }, {
        key: 'divider_2',
        itemType: ContextualMenuItemType.Divider,
      }, {
        key: 'custompresence',
        text: 'Custom Presence',
        iconProps: { iconName: 'CustomList' },
        onClick: _e => setShowCustomPresence(true),
      }, {
        key: 'clearoof',
        text: 'Clear Out Of Office',
        iconProps: { iconName: 'OutOfOffice' },
        disabled: !presence.isOutOfOffice,
        onClick: _e => { dispatch(actions.clearOutOfOffice()) },
      }, {
        key: 'setoof',
        text: 'Set Out Of Office',
        iconProps: { iconName: 'OutOfOffice' },
        disabled: presence.isOutOfOffice,
        onClick: _e => { setShowOutOfOfficeMessage(true) },
      }, {
        key: 'idleclient',
        text: 'Client Idle',
        iconProps: { iconName: 'CirclePause' },
        disabled: presence.idle,
        onClick: _e => { dispatch(actions.setClientIdle(true, null)) },
      }, {
        key: 'idleclientnow',
        text: 'Client Idle Now',
        iconProps: { iconName: 'CirclePause' },
        disabled: presence.idle,
        onClick: _e => { dispatch(actions.setClientIdle(true, dayjs().valueOf())) },
      }, {
        key: 'activeclient',
        text: 'Client Active',
        iconProps: { iconName: 'BoxPlaySolid' },
        disabled: !presence.idle,
        onClick: _e => { dispatch(actions.setClientIdle(false, null)) },
      }, {
        key: 'clearsp',
        text: 'Clear Scheduled Presences',
        iconProps: { iconName: 'CustomList' },
        onClick: _e => { dispatch(actions.clearScheduledPresences()) },
      }, {
        key: 'poppresence',
        text: 'Pop Presence',
        iconProps: { iconName: 'CustomList' },
        onClick: _e => { dispatch(actions.popPresence()) },
      },
    ]
  }, [createItem, dispatch, presence])

  const items: (IContextualMenuItem | Falsy)[] = [{
    key: 'header',
    onRender: () => <div className={css.menuHeader}>{children}</div>,
  }, {
    key: 'd1', itemType: ContextualMenuItemType.Divider,
  }, {
    key: 'presence',
    text: 'Presence',
    iconProps: { iconName: 'Clock' },
    disabled: session.active === false,
    secondaryText: getActivityPresenceStateText(presence),
    subMenuProps: {
      items: subItems,
    },
  }, {
    key: 'signout',
    text: 'Signout',
    iconProps: { iconName: 'SignOut' },
    disabled: session.active === false,
    onClick() {
      dispatch(actions.logout())
    },
}]


  return <>
    <ContextualMenu target={target} hidden={!open} onDismiss={() => setOpen(false)} items={items.filter(isTruthy)} />
    {showDuration && <Dialog isOpen onDismiss={() => setShowDuration(false)}>
      <DialogHeader></DialogHeader>
      <DialogBody className={css.setPresenceDurationDialog}>
        <SetPresenceDuration onDone={() => setShowDuration(false)} />
      </DialogBody>
    </Dialog>}    
    {showStatus && <Dialog isOpen onDismiss={() => setShowStatus(false)}>
      <DialogHeader></DialogHeader>
      <DialogBody className={css.setStatusDialog}>
        <SetStatus onDone={() => setShowStatus(false)} currentStatus={(presence.status && presence.status) || ''} />
      </DialogBody>
    </Dialog>}
    {showOutOfOfficeMessage && <Dialog isOpen onDismiss={() => setShowOutOfOfficeMessage(false)}>
      <DialogHeader></DialogHeader>
      <DialogBody className={css.setOutOfOfficeMessageDialog}>
        <SetOutOfOffice onDone={() => setShowOutOfOfficeMessage(false)} currentMessage={(presence.outOfOfficeMessage && presence.outOfOfficeMessage) || ''} />
      </DialogBody>
    </Dialog>}
    {showCustomPresence && <Dialog isOpen onDismiss={() => setShowCustomPresence(false)}>
      <DialogHeader></DialogHeader>
      <DialogBody className={css.setCustomPresenceDialog}>
        <SetCustomPresence onDone={() => setShowCustomPresence(false)} presence={presence} />
      </DialogBody>
    </Dialog>}
  </>
}
