import Page from "../Page";

import classNames from 'classnames'
import css from './ActivityPage.module.scss'
import { useSelector } from "../../store";
import Badge from "../presence/Badge";
import { useGraphUser } from "../../hooks";
import { UserPresenceStoreState } from "../../store/reducers/presence";
import dayjs from 'dayjs'
import { getGraphUserId } from "../../store/sagas/presence";
import { isEmptyOrSpaces } from "../../utils/text";
import { mapMap } from "../../utils/mapMap";

interface Props {
  className?: string
}

export default function ActivityPage({ className }: Props) {
  const userPresences = useSelector(s => s.presence.users)
  const haveUsers = userPresences.size > 0
  
  return <Page className={classNames(css.ActivityPage, className)}>
    <h1>Activity</h1>
    <div className={css.activePresence}>
      {!haveUsers && <span>No users currently connected</span>}
      {haveUsers && mapMap(userPresences, (value, id) => <UserCard key={id} id={id} presence={value} />)}
    </div>
  </Page>
}

interface UserCardProps {
  id: string
  presence: UserPresenceStoreState
}

function UserCard({ id, presence }: UserCardProps) {
  const graphUserId = getGraphUserId(presence.externalIds)
  const user = useGraphUser(graphUserId)

  return <div className={css.userCard}>
    <Badge key={id} className={css.photo} id={id} size='3rem'>{''}</Badge>
    <div className={css.details}>
      {user && <>
        <span className={css.name}>{user.displayName}</span>
        {user.jobTitle && <span>{user.jobTitle}</span>}
      </>}
      <span>{`${presence.availability}/${presence.activity}${presence.isOutOfOffice ? '/OutOfOffice' : ''}`}</span>
      {presence.isOutOfOffice && presence.outOfOfficeMessage && !isEmptyOrSpaces(presence.outOfOfficeMessage) && <span>{presence.outOfOfficeMessage}</span>}
      {presence.idle && !presence.idleSince && <span>Idle</span>}
      {presence.idle && presence.idleSince && <span>Idle since {dayjs(presence.idleSince).format('llll')}</span>}
      {presence.returnTime && <span>Returns @ {dayjs(presence.returnTime).format('llll')}</span>}
      {Object.keys(presence.customData).map(cid => <CustomData id={cid} value={presence.customData[cid]} key={id+cid} />)}
      {presence.status && <span>{presence.status}</span>}
      {Object.keys(presence.externalIds).filter(eid => eid !== '_id').map(eid => <ExternalId id={eid} value={presence.externalIds[eid]} key={id+eid} />)}
      <span>Updated @ {dayjs(presence.lastUpdate).format('llll')}</span>
    </div>
  </div>
}

interface ExternalIdProps {
  id: string
  value: string
}

function ExternalId({id, value}: ExternalIdProps) {
  return <div className={css.externalId}>{id}: {value}</div>
}

interface CustomDataProps {
  id: string
  value: string
}

function CustomData({id, value}: CustomDataProps) {
  return <div className={css.customData}>{id}: {value}</div>
}