import { PrimaryButton, TextField } from "@fluentui/react"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { actions } from "../../store"
import css from './SetOutOfOffice.module.scss'

export default function SetOutOfOffice({ onDone, currentMessage }: { onDone: React.DispatchWithoutAction, currentMessage: string }) {
    const [message, setMessage] = useState(currentMessage)
    const dispatch = useDispatch()
    
    const handleSet = useCallback(() => {
        console.log(`handleSet: message=${message}`)
        dispatch(actions.setOutOfOffice(message))
        onDone()
    }, [dispatch, onDone, message])

    return <form className={css.setOutOfOffice} onSubmit={() => handleSet()}>
        <TextField label='Out Of Office Message' value={message} onChange={(e, v) => setMessage(v ?? '')} autoFocus />
        <PrimaryButton type="button" onClick={() => handleSet()}>Set</PrimaryButton>
    </form>

}
