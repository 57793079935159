import { combineReducers } from "redux";
import login from "./login";
import graph from "./graph";
import presence from "./presence";

export function createRootReducer() {
    return combineReducers({     
      login,
      presence,
      graph,
    })
  }