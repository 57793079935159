import { IconButton, IIconProps } from "@fluentui/react";
import css from './CustomValue.module.scss'

interface CustomValueProps {
    keyname: string
    value: string
    onRemove(key: string): void 
}

export default function CustomValue({ keyname, value, onRemove } : CustomValueProps ) {
    const removeButtonIcon: IIconProps = { iconName: 'Delete' };
    return <div className={css.customValue}>
    <label>{keyname}</label>
    <label> = </label>
    <label>{value}</label>
    <IconButton iconProps={removeButtonIcon} onClick={(e) => onRemove(keyname)} />
 </div>
}
