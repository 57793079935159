import { DatePicker, DayOfWeek, Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react"
import dayjs, { ManipulateType, OpUnitType } from "dayjs"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { ActivityPresenceState, AvailabilityPresenceState } from "../../model"
import { actions } from "../../store"
import { PresenceBadge } from "./Badge"
import TimePicker from "../TimePicker"
import css from './SetPresenceDuration.module.scss'

function createOption(key: string, text: string, av: AvailabilityPresenceState, act: ActivityPresenceState) {
    return {
        key: key,
        text: text,
        onRenderIcon: () => <PresenceBadge className={css.presenceIcon} availability={av} isOutOfOffice={false} />,
        iconProps: {},
        data: { availability: av, activity: act },
        canCheck: true,
    }
}

const statusOptions = [
    createOption('busy', 'Busy', AvailabilityPresenceState.busy, ActivityPresenceState.busy),
    createOption('doNotDisturb', 'Do not disturb', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.doNotDisturb),
    createOption('beRightBack', 'Be right back', AvailabilityPresenceState.beRightBack, ActivityPresenceState.beRightBack),
    createOption('appearAway', 'Appear away', AvailabilityPresenceState.away, ActivityPresenceState.away),
    createOption('appearOffline', 'Appear offline', AvailabilityPresenceState.offline, ActivityPresenceState.offWork),
    createOption('presenting', 'Presenting', AvailabilityPresenceState.busy, ActivityPresenceState.presenting),
    createOption('focusing', 'Focusing', AvailabilityPresenceState.busy, ActivityPresenceState.focusing),
]

const resetOptions: IDropdownOption<{ count: number, unit: OpUnitType }>[] = [
    { key: '30minutes', text: '30 Minutes', data: { count: 30, unit: 'minute' } },
    { key: '1hour', text: '1 Hour', data: { count: 1, unit: 'hour' } },
    { key: '2Hours', text: '2 Hours', data: { count: 2, unit: 'hour' } },
    { key: 'Today', text: 'Today', data: { count: 0, unit: 'day' } },
    { key: 'ThisWeek', text: 'This Week', data: { count: 0, unit: 'week' } },
    { key: 'Custom', text: 'Custom', data: { count: 0, unit: 'year' } },
]

export default function SetPresenceDuration({ onDone }: { onDone: React.DispatchWithoutAction }) {
    const [selectedStatus, setSelectedStatus] = useState('busy')
    const [selectedReset, setSelectedReset] = useState('Today')
    const [customTime, setCustomTime] = useState(getDefaultCustomTime())
    const [customDate, setCustomDate] = useState<Date>(new Date())
    const dispatch = useDispatch()

    const handleSet = useCallback(() => {
        const data = statusOptions.find(opt => opt.key === selectedStatus)?.data
        const reset = resetOptions.find(opt => opt.key === selectedReset)?.data
        if (!reset || !data) return
        console.log(`handleSet: [${data.availability}/${data.activity}] selectedReset=${selectedReset}, customDate=${customDate}, customTime=${customTime}`, dayjs(customDate).startOf('day').add(customTime, 'hour'))
        const returnTime = selectedReset === 'Custom' ? dayjs(customDate).startOf('day').add(customTime, 'hour') : getReturnTime(reset)
        dispatch(actions.setPresence(data.availability, data.activity, null, returnTime.valueOf(), null, false, null))
        onDone()
    }, [customDate, customTime, dispatch, onDone, selectedReset, selectedStatus])

    return <form className={css.setPresenceDuration} onSubmit={() => handleSet()}>
        <label>Status</label>
        <Dropdown selectedKey={selectedStatus} options={statusOptions} onChange={(e, v) => v && setSelectedStatus(v.key as string)} />
        <label>Reset status after</label>
        <Dropdown selectedKey={selectedReset} options={resetOptions} onChange={(e, v) => v && setSelectedReset(v.key as string)} />
        {selectedReset === 'Custom' && <div className={css.customDuratioin}>
            <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers={true}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                value={customDate}
                onSelectDate={v => v && setCustomDate(v)}
            />
            <TimePicker value={customTime.valueOf()} onChange={v => setCustomTime(v)} />
        </div>}
        <PrimaryButton className={css.setbutton} type="button" onClick={() => handleSet()}>Set</PrimaryButton>
    </form>
}

function getDefaultCustomTime() {
    const now = dayjs()
    const startOfDay = now.startOf('day')
    return now.add(4, 'hour').startOf('hour').diff(startOfDay, 'h', true)
}

function getReturnTime(data: { count: number, unit: OpUnitType }) {
    if (data.count === 0) {
        return dayjs().endOf(data.unit)
    }
    return dayjs().add(data.count, data.unit as ManipulateType)
}

