import { createAction } from 'typesafe-actions'
import { PresenceUserState } from '../api/presence'
import { ActivityPresenceState, AvailabilityPresenceState, GraphUser } from '../model'
import { UserInfo } from './reducers/login'

export const login = createAction('LOGIN')<UserInfo>()

export const userPresencesChanged = createAction('USER_PRESENCES_CHANGED')<PresenceUserState[]>()
export const userPresenceDeleted = createAction('USER_PRESENCE_DELETED')<PresenceUserState>()
export const registerClientUser = createAction('REGISTER_CLIENT_USER')()
export const presenceRegistered = createAction('PRESENCE_REGISTERED')<{
  id: string;
} & PresenceUserState>()
export const setPresence = createAction('SET_PRESENCE', 
  (availability: AvailabilityPresenceState | null
    , activity: ActivityPresenceState | string | null
    , status: string | null
    , returnTime: number | null
    , customValues: Record<string, string> | null
    , pushCurrentPresence: boolean
    , preservePresenceWhenOffline: boolean | null
  ) => ({
   availability
   , activity
   , status
   , returnTime
   , customValues 
   , pushCurrentPresence
   , preservePresenceWhenOffline
  }))()
export const setClientIdle = createAction('SET_CLEINT_IDLE', (idle: boolean, sinceTime: number | null) => ({ idle, sinceTime }))()
export const setOutOfOffice = createAction('SET_OUT_OF_OFFICe', (message: string) => ({ message }))()
export const clearOutOfOffice = createAction('CLEAR_OUT_OF_OFFICe')()
export const presenceDisconnected = createAction('PRESENCE_DISCONNECTED')()
export const allPresencesLoaded = createAction('ALL_PRESENCES_LOADED')()
export const setScheduledPresence = createAction('SET_SCHEDULED_PRESENCE', (availability: AvailabilityPresenceState | null
    , activity: ActivityPresenceState | string | null
    , scheduledStartTime: number
    , duration: string | null
    , status: string | null
    , customData: Record<string, string> | null
    , pushCurrentPresence: boolean
    , preservePresenceWhenOffline: boolean | null
  ) => ({ availability, activity, scheduledStartTime, duration, status, customData, pushCurrentPresence, preservePresenceWhenOffline }))()
export const clearScheduledPresences = createAction('CLEAR_SCHEDULED_PRESENCES')()
export const popPresence = createAction('POP_PRESENCE')()

export const fetchPhoto = {
  request: createAction('FETCH_PHOTO_REQUEST')<string>(),
  response: createAction('FETCH_PHOTO_RESPONSE', (id: string, photo: Blob | null) => ({ id, photo }))(),
}
export const fetchGraphUser = {
  request: createAction('FETCH_GRAPH_USER_REQUEST')<string>(),
  response: createAction('FETCH_GRAPH_USER_RESPONSE')<GraphUser | null>(),
}

export const logout = createAction('LOGOUT')()
