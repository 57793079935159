import { PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";
import { isEmptyOrSpaces } from "../../utils/text";

import css from './RegisterTenantPage.module.scss'

export default function RegisterTenantPage() {
    const [tenant, setTenant] = useState('')

    function onSubmit() {
        if(isEmptyOrSpaces(tenant)) {
            alert('Must privide a tenant domain or tenant id')
            return
        }

        // "https://login.microsoftonline.com/{TenantName}/v2.0/adminconsent?client_id={ClientId}&redirect_uri={ConsentRedirectUri}&scope={ConsentScope}",
        let consentUrl = globalConfig
            .adminConsentUrl
            .replace("{TenantName}", tenant)
            .replace("{ClientId}", globalConfig.clientId)
            .replace("{ConsentRedirectUri}", encodeURIComponent(window.location.origin + '/tenantconsent'))
            .replace("{ConsentScope}", encodeURIComponent("https://graph.microsoft.com/.default"))
        console.log('admin constent url: ', consentUrl)
        window.location.replace(consentUrl)
    }

    return <form className={css.registerTenantPage}>
        <h2>Register Tenant</h2>
        <TextField label='Tenant Domain:    ' value={tenant} onChange={(e, v) => setTenant(v ?? '')} autoFocus />
        <PrimaryButton onClick={() => onSubmit()}>Register</PrimaryButton>
    </form>
}

