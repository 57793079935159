import classNames from 'classnames'
import { Extend } from '../utils/type'
import css from './Tabs.module.scss'

interface TabsProps {
  className?: string
}

export function Tabs({ className, children }: React.PropsWithChildren<TabsProps>) {
  return <ul className={classNames(css.tabs, className)}>
    {children}
  </ul>
}

type TabProps = Extend<{
  className?: string
  active?: boolean
  onClick?(): void
}, React.LiHTMLAttributes<HTMLLIElement>>

export function Tab({ className, children, active, onClick, ...props }: React.PropsWithChildren<TabProps>) {
  return <li tabIndex={0} role='button' className={classNames(css.tab, active && css.active, className)} onClick={onClick} {...props}>
    {children}
  </li>
}