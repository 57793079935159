import { AuthenticationResult, AuthError, BrowserAuthErrorMessage } from '@azure/msal-browser'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createAzureAdClient } from '../../api'
import { actions } from '../../store'
import css from './LoginPage.module.scss'

export default function LoginPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [needLogin, setNeedLogin] = useState(false)
    const [showReload, setShowReload] = useState(false)

    function reload() {
        window.location.reload()
    }

    const setToken = useCallback(async (authResult: AuthenticationResult) => {

        if (!authResult.account) {
            setShowReload(true)
            setError('An error occured fetching Azure token')
            return
        }
        const login = {
            oid: authResult.account.localAccountId,
            name: authResult.account.name!,
            upn: authResult.account.username,
        }

        dispatch(actions.login(login))
    }, [dispatch])


    useEffect(() => {
        let valid = true
        onLoad()
        return () => { valid = false }

        async function onLoad() {
            const client = createAzureAdClient('id')
            const res = await client.acquireSilent()
            if (!valid) { return }

            if (res) {
                setToken(res)
            } else {
                setNeedLogin(true)
            }
        }
    }, [dispatch, setToken])

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        setNeedLogin(false)

        try {
            const client = createAzureAdClient('id')
            const res = await client.acquirePopup()
            setToken(res)
        } catch (e) {
            const authError = e as AuthError
            if (authError.errorCode === BrowserAuthErrorMessage.userCancelledError.code) {
                setNeedLogin(true)
            } else {
                setError((e as Error).message ?? 'Error')
            }
        }
    }

    return <form className={css.loginPage} onSubmit={onSubmit}>
        {
            needLogin && <div className={css.buttons}>
                <button>Login with Azure AD</button>
                {globalConfig.multiTenantEnabled && <button type='button' onClick={() => navigate('/registertenant')}>Register Tenant</button> }
            </div>
        }
        {showReload && <button type='button' onClick={reload}>Reload</button>}
        {error ? <div className={css.error}>{error}</div> : needLogin ? null : 'Waiting for login...'}
    </form>
}