export type Extend<T, U> = Omit<T, keyof U> & U

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P]
}

export type Falsy = undefined | null | false | '' | 0

export function isDefined<T>(arg: T): arg is NonNullable<T> {
  return arg !== undefined && arg !== null
}

export const isTruthy = Boolean as unknown as <T>(arg: T) => arg is T extends Falsy ? never : NonNullable<T>
