import { Checkbox, ComboBox, DatePicker, DayOfWeek, Dropdown, IconButton, IDropdownOption, IIconProps, PrimaryButton, TextField } from "@fluentui/react"
import dayjs, { Dayjs, ManipulateType, OpUnitType } from "dayjs"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { ActivityPresenceState, AvailabilityPresenceState } from "../../model"
import { actions } from "../../store"
import { PresenceBadge } from "./Badge"
import TimePicker from "../TimePicker"
import css from './SetCustomPresence.module.scss'
import { UserPresenceStoreState } from "../../store/reducers/presence"
import AddCustomValue from "./AddCustomValue"
import CustomValue from "./CustomValue"
import { isEmptyOrSpaces } from "../../utils/text"
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

function createAvailabilityOption(key: string, text: string, av: AvailabilityPresenceState) {
    return {
        key: key,
        text: text,
        onRenderIcon: () => <PresenceBadge className={css.presenceIcon} availability={av} isOutOfOffice={false} />,
        iconProps: {},
        data: { availability: av },
        canCheck: true,
    }
}

const statusAvailabilityOptions = [
    createAvailabilityOption('Available', 'Available', AvailabilityPresenceState.available),
    createAvailabilityOption('Busy', 'Busy', AvailabilityPresenceState.busy),
    createAvailabilityOption('DoNotDisturb', 'Do not disturb', AvailabilityPresenceState.doNotDisturb),
    createAvailabilityOption('BeRightBack', 'Be right back', AvailabilityPresenceState.beRightBack),
    createAvailabilityOption('AppearAway', 'Appear away', AvailabilityPresenceState.away),
    createAvailabilityOption('AppearOffline', 'Appear offline', AvailabilityPresenceState.offline),
]

function createActivityOption(key: string, text: string, av: AvailabilityPresenceState, ac: ActivityPresenceState) {
    return {
        key: key,
        text: text,
        onRenderIcon: () => <PresenceBadge className={css.presenceIcon} availability={av} isOutOfOffice={false} />,
        iconProps: {},
        data: { activity: ac },
        canCheck: true,
    }
}

const statusActivityOptions = [
    createActivityOption('available', 'Available', AvailabilityPresenceState.available, ActivityPresenceState.available),
    createActivityOption('queue', 'Queue', AvailabilityPresenceState.available, ActivityPresenceState.queue),
    createActivityOption('busy', 'Busy', AvailabilityPresenceState.busy, ActivityPresenceState.busy),
    createActivityOption('doNotDisturb', 'DoNotDisturb', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.doNotDisturb),
    createActivityOption('beRightBack', 'BeRightBack', AvailabilityPresenceState.beRightBack, ActivityPresenceState.beRightBack),
    createActivityOption('appearAway', 'Away', AvailabilityPresenceState.away, ActivityPresenceState.away),
    createActivityOption('appearOffline', 'Offline', AvailabilityPresenceState.offline, ActivityPresenceState.offWork),
    createActivityOption('inACall', 'InACall', AvailabilityPresenceState.busy, ActivityPresenceState.inACall),
    createActivityOption('inAConferenceCall', 'InAConferenceCall', AvailabilityPresenceState.busy, ActivityPresenceState.inAConferenceCall),
    createActivityOption('inAMeeting', 'InAMeeting', AvailabilityPresenceState.busy, ActivityPresenceState.inAMeeting),
    createActivityOption('presenting', 'Presenting', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.presenting),
    createActivityOption('urgentInterruptionsOnly', 'UrgentInterruptionsOnly', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.urgentInterruptionsOnly),
    createActivityOption('focusing', 'Focusing', AvailabilityPresenceState.doNotDisturb, ActivityPresenceState.focusing)
]

const resetOptions: IDropdownOption<{ count: number, unit: OpUnitType }>[] = [
    { key: 'Never', text: 'Never' },
    { key: '30minutes', text: '30 Minutes', data: { count: 30, unit: 'minute' } },
    { key: '1hour', text: '1 Hour', data: { count: 1, unit: 'hour' } },
    { key: '2Hours', text: '2 Hours', data: { count: 2, unit: 'hour' } },
    { key: 'Today', text: 'Today', data: { count: 0, unit: 'day' } },
    { key: 'ThisWeek', text: 'This Week', data: { count: 0, unit: 'week' } },
    { key: 'Custom', text: 'Custom', data: { count: 0, unit: 'year' } },
]

const preservePresenceOptions: IDropdownOption<{ value: boolean | null }>[] = [
    { key: 'Leave', text: 'Leave Alone', data: { value: null } },
    { key: 'PreservePresence', text: 'Preserve Presence', data: { value: true } },
    { key: 'GoOffline', text: 'Go Offline', data: { value: false } },
]

function RemoveCustomValueKey(customValues:Record<string,string>, removeKey:string)
{
    return Object.entries(customValues).filter((entry) => entry[0] !== removeKey).reduce<Record<string, string>>((acc, entry) => { acc[entry[0]] = entry[1]; return acc }, {})
}

function AddCustomValueKey(customValues:Record<string,string>, key:string, value:string)
{
    customValues[key] = value
    return customValues;
}

export default function SetCustomPresence({ onDone, presence }: { onDone: React.DispatchWithoutAction, presence:UserPresenceStoreState }) {
    const [selectedAvailability, setSelectedAvailability] = useState(presence.availability as string)
    const [selectedActivity, setSelectedActivity] = useState(presence.activity as string)
    const [scheduleStatus, setScheduleStatus] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(presence.status ?? '')
    const [selectedReturnTime, setSelectedReturnTime] = useState(presence.returnTime ? 'Custom' : 'Never')
    const [customReturnTime, setCustomReturnTime] = useState(presence.returnTime ? getCustomTime(dayjs(presence.returnTime)) : getDefaultCustomTime())
    const [customReturnDate, setCustomReturnDate] = useState<Date>(presence.returnTime ? dayjs(presence.returnTime).toDate() : new Date())
    const [selectedScheduledStartTime, setSelectedScheduledStartTime] = useState('Never')
    const [customScheduledStartTime, setCustomScheduledStartTime] = useState(getDefaultCustomTime())
    const [customScheduledStartDate, setCustomScheduledStartDate] = useState<Date>(new Date())
    const [selectedCustomValues, setSelectedCustomValues] = useState({...presence.customData})
    const [showCustomValueAdd, setShowCustomValueAdd] = useState(false)
    const [pushPresence, setPushPresence] = useState(false)
    const [preservePresenceWhenOffline, setPreservePresenceWhenOffline] = useState('Leave')
    const dispatch = useDispatch()
    const addButtonIcon: IIconProps = { iconName: 'Add' };

    const handleSet = useCallback(() => {
        const data = statusAvailabilityOptions.find(opt => opt.key === selectedAvailability)?.data
        if (!data) return
        const activity = isEmptyOrSpaces(selectedActivity) ? data.availability : selectedActivity
        const scheduledTime = getCustomTimeFromValues(selectedScheduledStartTime, customScheduledStartDate, customScheduledStartTime, dayjs())
        const preservePresenceWhenOfflineValue = preservePresenceOptions.find(opt => opt.key === preservePresenceWhenOffline)?.data?.value ?? null
        console.log(`handleSet: [${data.availability}/${activity}]`)
        console.log(`  CustomValues: [${selectedCustomValues}]`)
        console.log(`  PreservePresence: [${preservePresenceWhenOffline}](${preservePresenceWhenOfflineValue})`)
        console.log(`  PushPresence: [${pushPresence}]`)
    if(scheduledTime) {
            const returnTime = getCustomTimeFromValues(selectedReturnTime, customReturnDate, customReturnTime, scheduledTime)
            if(scheduleStatus) {
                console.log(`  Status=[${selectedStatus}]`)
            }
            console.log(`  ScheduledStartTime=${selectedScheduledStartTime}, ScheduledDate=${scheduledTime?.format('YYYY-MM-DDTHH:mm:ss')}`)
            console.log(`  ReturnTime=${returnTime?.format('YYYY-MM-DDTHH:mm:ss')}`)
            const duration = (returnTime && returnTime > scheduledTime) ? dayjs.duration(returnTime.diff(scheduledTime)).format('HH:mm:ss') : null
            console.log(`  ScheduledDuration=${duration}`)
            dispatch(actions.setScheduledPresence(data.availability, activity, scheduledTime.valueOf(), duration, scheduleStatus ? selectedStatus : null, selectedCustomValues, pushPresence, preservePresenceWhenOfflineValue))
        } else {
            const returnTime = getCustomTimeFromValues(selectedReturnTime, customReturnDate, customReturnTime, dayjs())
            console.log(`  Status=[${selectedStatus}]`)
            console.log(`  ReturnTime=${selectedReturnTime}, ReturnDate=${returnTime?.format('YYYY-MM-DDTHH:mm:ss')}`)
            dispatch(actions.setPresence(data.availability, activity, selectedStatus, returnTime ? returnTime.valueOf() : null, selectedCustomValues, pushPresence, preservePresenceWhenOfflineValue))
        }

        onDone()
    }, 
    [dispatch
        , onDone
        , selectedReturnTime
        , customReturnDate
        , customReturnTime
        , scheduleStatus
        , selectedStatus
        , selectedAvailability
        , selectedActivity
        , selectedCustomValues
        , selectedScheduledStartTime
        , customScheduledStartDate
        , customScheduledStartTime
        , pushPresence
        , preservePresenceWhenOffline])

    const handleAdd = useCallback((key:string, value:string) => {
        setSelectedCustomValues(AddCustomValueKey(selectedCustomValues, key, value))
    }, [setSelectedCustomValues, selectedCustomValues])

    const handleRemove = useCallback((key:string) => {
        setSelectedCustomValues(RemoveCustomValueKey(selectedCustomValues, key))
    }, [setSelectedCustomValues, selectedCustomValues])

    const setAvailability = (e : React.FormEvent<HTMLDivElement>, v: IDropdownOption<any> | undefined) => {
        if(v) {
            setSelectedAvailability(v.key as string)
            setSelectedActivity(v.key as string)
        }
    }

    return <form className={css.setPresenceDuration} onSubmit={() => handleSet()}>
        <label>Availability</label>
        <Dropdown selectedKey={selectedAvailability} options={statusAvailabilityOptions} onChange={setAvailability} />
        <label>Activity</label>
        <ComboBox text={selectedActivity} options={statusActivityOptions} allowFreeform={true} onChange={(_e, _so, _i, v) => v && setSelectedActivity(v)} />
        {selectedScheduledStartTime !== 'Never' &&  <Checkbox onChange={(_e, v) => setScheduleStatus(!!v)} checked={scheduleStatus} label="Schedule Status Change"/>}
        {(scheduleStatus || selectedScheduledStartTime === 'Never') && <TextField label='Status Text' value={selectedStatus} onChange={(_e, v) => v != null && setSelectedStatus(v)} />}
        <label>Reset status after</label>
        <Dropdown selectedKey={selectedReturnTime} options={resetOptions} onChange={(e, v) => v && setSelectedReturnTime(v.key as string)} />
        {selectedReturnTime === 'Custom' && <div className={css.customDuratioin}>
            <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers={true}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                value={customReturnDate}
                onSelectDate={v => v && setCustomReturnDate(v)}
            />
            <TimePicker value={customReturnTime.valueOf()} onChange={v => setCustomReturnTime(v)} />
        </div>}
        <label>Offline Presence</label>
        <Dropdown selectedKey={preservePresenceWhenOffline} options={preservePresenceOptions} onChange={(_e, v) => v && setPreservePresenceWhenOffline(v.key as string)} />
        <label>Scheduled set presence</label>
        <Dropdown selectedKey={selectedScheduledStartTime} options={resetOptions} onChange={(_e, v) => v && setSelectedScheduledStartTime(v.key as string)} />
        {selectedScheduledStartTime === 'Custom' && <div className={css.customDuratioin}>
            <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers={true}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                value={customScheduledStartDate}
                onSelectDate={v => v && setCustomScheduledStartDate(v)}
            />
            <TimePicker value={customScheduledStartTime.valueOf()} onChange={v => setCustomScheduledStartTime(v)} />
        </div>}
        <div className={css.customDataValues}>
            <label>Custom Values:</label>
            {Object.entries(selectedCustomValues).map(([key, value]) => (<CustomValue key={key} keyname={key} value={value} onRemove={handleRemove} />))}
            {!showCustomValueAdd && <IconButton iconProps={addButtonIcon} onClick={(_e) => setShowCustomValueAdd(true)} /> }
            {showCustomValueAdd && <AddCustomValue onDone={() => setShowCustomValueAdd(false)} onAdd={handleAdd} /> }
        </div>
        <Checkbox onChange={(_e, v) => setPushPresence(!!v)} checked={pushPresence} label="Push current presence"/>
        <PrimaryButton className={css.setbutton} type="button" onClick={() => handleSet()}>Set</PrimaryButton>
    </form>
}

function getDefaultCustomTime() {
    const now = dayjs()
    return getCustomTime(now)
}

function getCustomTime(returnTime: Dayjs) {
    const startOfDay = returnTime.startOf('day')
    return returnTime.add(4, 'hour').startOf('hour').diff(startOfDay, 'h', true)
}

function getCustomTimeFromValues(resetOption:string, customDate:Date, customTime:number, date: Dayjs) {
    const reset = resetOptions.find(opt => opt.key === resetOption)?.data
    return resetOption === 'Custom' ? dayjs(customDate).startOf('day').add(customTime, 'hour') : (reset == null ? null : getReturnTime(reset, date))
}

function getReturnTime(data: { count: number, unit: OpUnitType }, date: Dayjs) {
    if (data.count === 0) {
        return date.endOf(data.unit)
    }
    return date.add(data.count, data.unit as ManipulateType)
}

