import { createPromiseSource, delay } from '../utils/promise'
import * as signalR from '@microsoft/signalr'
import { acquireToken } from '.'
import { backoff, createBearerTokenEnricher, ensureSuccess, reqInit, withEnricher } from '../utils/network'

export interface PresenceUserState {
  availability: string | undefined
  activity: string | undefined
  returnTime: string | undefined
  status: string | undefined
  idle: boolean
  idleSince: string | undefined
  isOutOfOffice: boolean
  outOfOfficeMessage: string | undefined
  externalIds: Record<string, string>
  customData: Record<string, string>
}

export type PresenceNotificationHandler = {
  registerClientUserResponse(state: { id: string } & PresenceUserState): void
  userPresenceChange(state: PresenceUserState): void
  userPresenceDelete(state: PresenceUserState): void

  onConnected(reconnect: boolean): void
  onDisconnected(err: Error|undefined): void
}

let connection: signalR.HubConnection
let connected = createPromiseSource<null>()
let wasConnected = false

export default async function presenceNotifications(getToken: () => Promise<string>, handler: PresenceNotificationHandler) {
  connection = new signalR.HubConnectionBuilder()
    .withUrl('/client', { accessTokenFactory: getToken })
    // .configureLogging(signalR.LogLevel.Debug)
    .build()

  connection.on('RegisterClientUserResponse', (arg) => handler.registerClientUserResponse(arg))
  connection.on('UserPresenceChange', (arg1) => handler.userPresenceChange(arg1))
  connection.on('UserPresenceDeleted', (arg1) => handler.userPresenceDelete(arg1))

  let retries = 0
  let reconnecting = false

  connection.onclose(err => {
    console.log('info', 'presence connection closed', err)

    handler.onDisconnected(err)
    if (wasConnected) {
      connected = createPromiseSource()
    }

    reconnect()
  })

  reconnect()
  return () => connection.stop()

  async function reconnect() {
    if (reconnecting) { return }
    reconnecting = true

    await connection.stop().catch(() => { })

    if (wasConnected) {
      console.log('presence reconnecting')
    }

    for (; ;) {
      try {
        await delay(backoff(retries++))
        await connection.start()
        break
      } catch (e) {
        console.error('presence error connecting', e)
      }
    }

    try {
      handler.onConnected(wasConnected)

      reconnecting = false
      retries = 0
      wasConnected = true
      connected.resolve(null)

      console.info('presence connected')
    } catch (e) {
      console.error('presence error subscribing', e)
      reconnect()
    }
  }
}

export async function registerClient(clientName: string) {
  await connected.promise
  await connection.invoke('RegisterClient', { clientName })
}

interface RegisterClientUserParam {
  defaultActivity?: string
  defaultAvailability?: string
  defaultStatus?: string
  idle: boolean
  idleSince?: string
  externalIds?: Record<string, string>
  customData?: Record<string, string>
}

export async function registerClientUser(params: RegisterClientUserParam) {
  await connected.promise
  await connection.invoke('RegisterClientUser', params)
}


interface SetClientUserPresenceParam {
  presenceId: string
  availability?: string
  activity?: string
  returnTime?: string
  status?: string
  customData?: Record<string, string>
  pushCurrentPresence: boolean
  preservePresenceWhenOffline?: boolean
}

export async function setClientUserPresence(param: SetClientUserPresenceParam) {
  await connected.promise
  await connection.invoke('SetClientUserPresence', param)
}

interface SetClientUserIdleParam {
  presenceId: string
  idle: boolean
  idleSince?: string
}

export async function setClientUserIdle(param: SetClientUserIdleParam) {
  await connected.promise
  console.log(param)
  await connection.invoke('IdleClientUser', param)
}

interface ClientUserSetOutOfOfficeParam {
  presenceId: string
  message?: string
}

export async function setClientUserOutOfOffice(param: ClientUserSetOutOfOfficeParam) {
  await connected.promise
  await connection.invoke('SetOutOfOffice', param)
}

interface ClearClientUserOutOfOfficeParam {
  presenceId: string
}

export async function clearClientUserOutOfOffice(param: ClearClientUserOutOfOfficeParam) {
  await connected.promise
  await connection.invoke('ClearOutOfOffice', param)
}

interface ScheduleSetClientUserPresenceParam {
  presenceId: string
  availability: string
  activity: string
  startTime: string
  duration?: string
  status?: string
  customData?: Record<string, string>
  pushCurrentPresence: boolean
  preservePresenceWhenOffline?: boolean
}

export async function scheduleSetClientUserPresence(param: ScheduleSetClientUserPresenceParam) {
  await connected.promise
  await connection.invoke('ScheduleSetClientUserPresence', param)
}

interface ClearScheduledClientUserPresencesParam {
  presenceId: string
}

export async function clearScheduledClientUserPresences(param: ClearScheduledClientUserPresencesParam) {
  await connected.promise
  await connection.invoke('ClearScheduledClientUserPresences', param)
}

interface PopClientUserPresenceParam {
  presenceId: string
}

export async function popClientUserPresence(param: PopClientUserPresenceParam) {
  await connected.promise
  await connection.invoke('PopClientUserPresence', param)
}

export interface PageinatedList<T> {
  items: T[]
  pageNumber: number
  totalPages: number
  totalCount: number
}

interface ClientUserId {
  type: string
  externalUserId: string
}

interface FetchPresenceParam {
  clients: ClientUserId[]
  pageNumber: number
  pageSize?: number
}

export async function fetchPresence(gid: string): Promise<PresenceUserState | null> {
  const param: FetchPresenceParam = {
    clients: [{ type: "GraphUserId", externalUserId: gid }],
    pageNumber: 1
  }
  const res = await execute<PageinatedList<PresenceUserState>>('/api/UserPresence', reqInit('PUT', param))

  return res.items.length > 0 ? res.items[0] : null
}

export async function fetchAllPresencesPage(page: number, pageSize:number|undefined = undefined): Promise<PageinatedList<PresenceUserState>> {
  const param: FetchPresenceParam = {
    clients: [],
    pageNumber: page,
    pageSize: pageSize,
  }

  return await execute<PageinatedList<PresenceUserState>>('/api/UserPresence', reqInit('PUT', param))
}

async function execute<T>(url: string, init?: RequestInit) {
  const resp = await fetch(url, await withEnricher(getBearerTokenEnricher(), init))
  await ensureSuccess(resp)
  return await resp.json() as T
}

export function getBearerTokenEnricher() {
  return createBearerTokenEnricher(() => acquireToken('api'))
}
